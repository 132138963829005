<template>
  <v-container fluid class="px-6 py-6">
    <div class="site-detail rounded" id="page_header" v-if="dataLoaded">
      <h2 id="siteName" v-if="insideSiteModal">
        <small>{{ this.currentSite.name }}</small>
      </h2>
      <h2 v-else>
        <small>{{ this.currentSite.name }}</small>
      </h2>
      <v-btn
        v-if="insideSiteModal"
        :ripple="false"
        :elevation="0"
        class="font-weight-bold text-xs btn-default btn-outline-default"
        color="transparent"
        id="nativeLink_btn"
        @click="openWindow(currentSite.nativeMonitoringLink)"
      >
        <img
          :src="
            require('@/assets/img/' +
              this.currentSite.monitoringSystem +
              '.png')
          "
        />
      </v-btn>
      <v-btn
        v-if="currentSite.sitePlans && insideSiteModal"
        :ripple="false"
        :elevation="0"
        class="font-weight-bold text-xs btn-default bg-gradient-default"
        id="sites_btn"
        @click="openWindow(currentSite.sitePlans)"
      >
        Site Plans
      </v-btn>
      <v-btn
        v-if="insideSiteModal"
        :ripple="false"
        :elevation="0"
        class="font-weight-bold text-xs btn-default bg-gradient-default"
        id="inverters_btn"
        @click="openWindow(`/sites/` + currentSite.id + `/inverters_list`)"
      >
        Inverters
      </v-btn>
      <v-tabs id="pageTabs" grow v-model="tab">
        <v-tab href="#metrics" v-if="!insideSiteModal"> Metrics </v-tab>
        <v-tab href="#info"> Info </v-tab>
        <!-- <v-tab @click="clickInverterTab()" href="#inverters">
        Inverters
      </v-tab> -->
        <v-tab href="#additional-info"> Additional Info </v-tab>
        <v-tab
          v-show="
            user.claims.user_role == 'super_user' ||
            user.claims.user_role == 'customer_admin'
          "
          href="#financials"
        >
          Financials
        </v-tab>
      </v-tabs>

      <v-tabs-items id="metrics_background" class="mb-6" v-model="tab">
        <v-tab-item value="metrics">
          <v-card flat class="card-shadow" id="metrics_card">
            <v-card-text>
              <v-row justify="end">
                <!-- <v-col sm="4" class="d-flex justify-center">
              <v-btn color="primary" @click="backloadData(365)">
                Backload Data for 365 days
              </v-btn>
            </v-col> -->
              </v-row>
              <v-row justify="end">
                <!-- <v-col sm="4" class="d-flex justify-center">
              <v-btn color="primary" @click="backloadData(7)">
                Backload Data for 7 days
              </v-btn>
            </v-col> -->
              </v-row>
              <new-site-metrics
                v-bind:site_id="this.currentSite.id"
                v-bind:nativeLink="this.currentSite.nativeMonitoringLink"
                v-bind:system="this.currentSite.monitoringSystem"
                v-bind:plans="this.currentSite.sitePlans"
              ></new-site-metrics>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item value="info">
          <v-card
            :disabled="user.claims.user_role == 'customer_view'"
            flat
            id="info_card"
          >
            <v-card-text>
              <v-row style="padding: 10px">
                <v-col cols="8">
                  <v-form v-if="fields">
                    <v-card class="card-shadow border-radius-xl mt-4 mb-4 mr-4">
                      <div class="pb-0 px-4 pt-4">
                        <div class="d-flex align-items-center">
                          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                            General Information
                          </h6>
                        </div>
                      </div>
                      <v-row justify="start">
                        <v-col
                          cols="5"
                          class="ml-11 mr-6"
                          v-for="field in ['name', 'customerName']"
                          :key="fields[field].value"
                        >
                          <site-detail-input :field="fields[field]" />
                        </v-col>
                      </v-row>

                      <v-row justify="start">
                        <v-col
                          cols="5"
                          class="ml-11 mr-6"
                          v-for="field in [
                            'customerAccountName',
                            'checkFrequency',
                          ]"
                          :key="fields[field].value"
                        >
                          <site-detail-input :field="fields[field]" />
                        </v-col>
                      </v-row>
                      <div class="pb-0 px-4 pt-4">
                        <div class="d-flex align-items-center">
                          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                            Address
                          </h6>
                        </div>
                      </div>
                      <v-row justify="start">
                        <v-col
                          cols="5"
                          class="ml-11 mr-6"
                          v-for="field in ['street', 'city']"
                          :key="fields[field].value"
                        >
                          <site-detail-input :field="fields[field]" />
                        </v-col>
                      </v-row>

                      <v-row justify="start">
                        <v-col
                          cols="5"
                          class="ml-11 mr-6"
                          v-for="field in ['state', 'postalCode']"
                          :key="fields[field].value"
                        >
                          <site-detail-input :field="fields[field]" />
                        </v-col>
                      </v-row>
                      <!-- new contact section -->
                      <div class="pb-0 px-4 pt-4">
                        <div class="d-flex align-items-center">
                          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                            Contact
                          </h6>
                        </div>
                      </div>
                      <v-row justify="start">
                        <v-col
                          
                          cols="10"
                          class="ml-12 mr-n1"
                          >
                          <v-data-table
                            :headers="headers"
                            :items="items"
                            :items-per-page="5"
                            class="elevation-1"
                          >
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-btn
                                  :disable="user.claims.user_role == 'customer_view'"
                                  :ripple="false"
                                  :elevation="0"
                                  class="
                                  font-weight-bold
                                  text-xs
                                  btn-default
                                  bg-gradient-default" 
                                  id="newUser_btn" 
                                  @click="startNewContact"
                                >
                                  Add Contact
                                </v-btn>
                              </v-toolbar>
                            </template>
                            <template v-slot:item.exclude_from_report="props">
                              <div style="padding-top: 10px;">
                                <v-checkbox 
                                  v-model="props.item.exclude_from_report"
                                  @change="(e) => reportExclude(e, props.item)"
                                ></v-checkbox>
                              </div>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-btn
                                class="mx-2"
                                icon
                                @click="copyToClipboard(`${item.name} ${item.last_name} ${item.email} ${item.phone}`)" 
                              >
                                <v-icon size="18">mdi-content-copy</v-icon>
                              </v-btn>
                             
                            </template> 
                            <template v-slot:item.controls="props">
                              <v-btn
                                class="mx-2"
                                icon
                                color="red"
                                @click="deleteContact(props.item)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>  
                          </v-data-table>
                        </v-col>
                      </v-row>

                      <!-- New reporting group table -->
                      <div class="pb-0 px-4 pt-4">
                        <div class="d-flex align-items-center">
                          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                            Reporting Groups
                          </h6>
                        </div>
                      </div>
                      <v-row justify="start">
                        <v-col
                          
                          cols="10"
                          class="ml-12 mr-n1"
                          >
                          <v-data-table
                            :headers="groupHeaders"
                            :items="groupItems"
                            :items-per-page="5"
                            class="elevation-1"
                          >
                          </v-data-table>
                        </v-col>
                      </v-row>

                      <div class="pb-0 px-4 pt-4">
                        <div class="d-flex align-items-center">
                          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                            Monitoring
                          </h6>
                        </div>
                      </div>
                      <v-row justify="start">
                        <v-col
                          cols="5"
                          class="ml-11 mr-6"
                          v-for="field in ['monitoringSystem', 'installYear']"
                          :key="fields[field].value"
                        >
                          <site-detail-input :field="fields[field]" />
                        </v-col>
                      </v-row>
                      <div class="pb-0 px-4 pt-4">
                        <div class="d-flex align-items-center">
                          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                            Settings
                          </h6>
                        </div>
                      </div>
                      <v-row justify="start">
                        <v-col class="ml-4" cols="3">
                          <v-checkbox
                            class="ml-6"
                            @change="isArchivedChanged"
                            v-model="isArchived"
                            ><template v-slot:label>
                              <div>
                                <p
                                  class="
                                    text-sm
                                    mb-0
                                    text-capitalize
                                    font-weight-bolder
                                  "
                                >
                                  Archived
                                </p>
                              </div>
                            </template>
                          </v-checkbox>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox
                            class="ml-3"
                            v-model="isPaused"
                            @change="isPausedChecked"
                          >
                            <template v-slot:label>
                              <div>
                                <p
                                  class="
                                    text-sm
                                    mb-0
                                    text-capitalize
                                    font-weight-bolder
                                  "
                                >
                                  Paused
                                </p>
                              </div>
                            </template>
                          </v-checkbox>
                          <div class="text-center">
                            <v-dialog
                              v-model="check_dialog"
                              width="500"
                            >
                              <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                  Pausing/Unpausing Site
                                </v-card-title>

                                <v-card-text class="text-h8 mt-4">
                                  Are you sure? **Triple check** if there is a case open related to this before changing. This will be paused until it's manually undone.
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    class="
                                      font-weight-bold
                                      text-xs
                                      btn-danger
                                      bg-gradient-danger
                                    "
                                    @click="cancelPause"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    class="
                                      font-weight-bold
                                      text-xs
                                      btn-success
                                      bg-gradient-success
                                    "
                                    @click="isPausedChanged"
                                  >
                                    Continue
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </div>
                        </v-col>
                      </v-row>
                      <div class="pb-0 px-4 pt-4">
                        <div class="d-flex align-items-center">
                          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                            Refresh Settings
                          </h6>
                        </div>
                      </div>
                      <v-row justify="start">
                        <v-col class="ml-4" cols="3">
                          <!-- <v-text-field v-model="count" type="number" step="5" @input="onInput" /> -->
  <v-text-field v-model="refreshDays" :mask="mask" @input="onRefreshDaysInput" label="Days to Refresh" outlined class="mr-4" style="max-width: 120px;" />
                          <!-- <spin-button v-model="count" :step="2"></spin-button> -->
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-form>
                </v-col>
                <v-col class="mt-4" cols="4">
                  <v-card>
                    <div class="bg-gradient-primary shadow-primary">
                      <div class="pb-0 px-4 pt-4">
                        <div class="d-flex align-items-center">
                          <h6
                            class="
                              text-white text-h6 text-typo
                              mb-4
                              font-weight-bold
                            "
                          >
                            Add Notes
                          </h6>
                        </div>
                      </div>
                      <div class="row mt-n7 mb-2">
                        <form class="ml-6 col-10" @submit.prevent="addNote()">
                          <v-text-field
                            label="Create a new note..."
                            color="white"
                            class="white-field"
                            v-model="newNote"
                          ></v-text-field>
                        </form>
                      </div>
                    </div>
                  </v-card>
                  <v-card class="mt-6">
                    <div>
                      <div class="pb-0 px-4 pt-4">
                        <div class="d-flex align-items-center">
                          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                            Notes
                          </h6>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <ul
                            class="list-group mb-n2"
                            style="padding-left: 16px; padding-right: 10px"
                          >
                            <note
                              v-for="note in notes"
                              :description="note.note"
                              :date="note.date"
                              :id="note.id"
                              @on-toggle="toggleNote(note)"
                              @on-delete="deleteNote(note)"
                              @on-edit="editNote(note, $event)"
                              @on-archive="archiveNote(note)"
                            />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </v-card>
                  <v-btn class="mt-6" @click="showHistoryModal">History</v-btn>
                  <v-card class="mt-6">
                    <div>
                      <div class="pb-0 px-4 pt-4">
                        <div class="d-flex align-items-center">
                          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                            Customer Notes
                          </h6>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <ul
                            class="list-group mb-n2"
                            style="padding-left: 16px; padding-right: 10px"
                          >
                            <note
                              v-for="note in customer_notes"
                              :description="note.note"
                              :date="note.date"
                              :id="note.id"
                              @on-toggle="toggleNote(note)"
                              @on-delete="deleteNote(note)"
                              @on-edit="editNote(note, $event)"
                              @on-archive="archiveNote(note)"
                            />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <v-row justify="start" v-if="!insideSiteModal">
                <v-col sm="6">
                  <v-btn
                    :disabled="
                      user.claims.user_role == 'customer_edit' ||
                      user.claims.user_role == 'customer_view'
                    "
                    :ripple="false"
                    :elevation="0"
                    class="
                      font-weight-bold
                      text-xs
                      btn-default
                      bg-gradient-danger
                      ml-2
                    "
                    color="error"
                    @click="toggleShowConfirmModal(true)"
                  >
                    Remove Site
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="additional-info">
          <v-card
            :disabled="user.claims.user_role == 'customer_view'"
            flat
            id="additional_card"
          >
            <v-card-text>
              <v-form v-if="fields">
                <v-card
                  class="card-shadow border-radius-xl mt-4 mb-4 mr-10 ml-10"
                >
                  <div class="pb-0 px-8 pt-4">
                    <div class="d-flex align-items-center">
                      <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                        Additional Information
                      </h6>
                    </div>
                  </div>
                  <v-row justify="start">
                    <v-col
                      cols="5"
                      class="ml-16 mr-8"
                      v-for="field in [
                        'inverterManufacturer',
                        'solarModuleWattage',
                      ]"
                      :key="fields[field].value"
                    >
                      <site-detail-input :field="fields[field]" />
                    </v-col>
                  </v-row>

                  <v-row justify="start">
                    <v-col
                      cols="5"
                      class="ml-16 mr-8"
                      v-for="field in ['internalId', 'component2']"
                      :key="fields[field].value"
                    >
                      <site-detail-input :field="fields[field]" />
                    </v-col>
                  </v-row>

                  <v-row justify="start">
                    <v-col
                      cols="5"
                      class="ml-16 mr-8"
                      v-for="field in [
                        'annualProductionEstimate',
                        'secondaryMonitoringSystem',
                      ]"
                      :key="fields[field].value"
                    >
                      <site-detail-input :field="fields[field]" />
                    </v-col>
                  </v-row>

                  <div class="pb-0 px-8 pt-4">
                    <div class="d-flex align-items-center">
                      <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                        Shade
                      </h6>
                    </div>
                  </div>
                  <v-row justify="start">
                    <v-col
                      cols="3"
                      class="ml-16 mr-11"
                      v-for="field in [
                        'shadeSummer',
                        'shadeFallSpring',
                        'shadeWinter',
                      ]"
                      :key="fields[field].value"
                    >
                      <site-detail-input :field="fields[field]" />
                    </v-col>
                  </v-row>

                  <div class="pb-0 px-8 pt-4">
                    <div class="d-flex align-items-center">
                      <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                        Site Plans
                      </h6>
                    </div>
                  </div>
                  <v-row justify="start" align="baseline">
                    <v-col
                      cols="5"
                      class="ml-16"
                      v-for="field in ['sitePlans']"
                      :key="fields[field].value"
                    >
                      <site-detail-input :field="fields[field]" />
                    </v-col>
                    <v-col sm="6">
                      <a
                        href=""
                        v-if="currentSite.sitePlans"
                        v-on:click.stop.prevent="
                          openWindow(currentSite.sitePlans)
                        "
                        >{{ currentSite.sitePlans }}</a
                      >
                    </v-col>
                  </v-row>

                  <div class="pb-0 px-8 pt-4">
                    <div class="d-flex align-items-center">
                      <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                        Native Monitoring
                      </h6>
                    </div>
                  </div>
                  <v-row justify="start">
                    <v-col
                      cols="5"
                      class="ml-16 mr-8"
                      v-for="field in ['monitoringSiteId', 'monitoringApiKey']"
                      :key="fields[field].value"
                    >
                      <site-detail-input :field="fields[field]" />
                    </v-col>
                  </v-row>

                  <v-row justify="start">
                    <v-col
                      cols="5"
                      class="ml-16 mr-8"
                      v-for="field in ['nativeMonitoringLink']"
                      :key="fields[field].value"
                    >
                      <site-detail-input :field="fields[field]" />
                    </v-col>
                  </v-row>
                  <div class="pb-0 px-8 pt-4">
                    <div class="d-flex align-items-center">
                      <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                        Case Management
                      </h6>
                    </div>
                  </div>
                  <v-row justify="start">
                    <v-col class="ml-2" cols="12">
                      <v-checkbox
                        class="ml-5"
                        v-model="managingCases"
                        @change="managingCasesChanged"
                        ><template v-slot:label>
                          <div>
                            <p
                              class="
                                text-sm
                                mb-0
                                text-capitalize
                                font-weight-bolder
                              "
                            >
                              Sun-o-vision is responsible for active case management on this site (coordinating onsite work, etc.)
                            </p>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <div class="pb-0 px-8 pt-4">
                    <div class="d-flex align-items-center">
                      <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                        Preferences
                      </h6>
                    </div>
                  </div>
                  <v-row justify="start">
                    <v-col class="ml-2" cols="3">
                      <v-checkbox
                        class="ml-5"
                        v-model="annualVisit"
                        @change="annualVisitChanged"
                        ><template v-slot:label>
                          <div>
                            <p
                              class="
                                text-sm
                                mb-0
                                text-capitalize
                                font-weight-bolder
                              "
                            >
                              Annual Visit
                            </p>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col class="ml-2" cols="3">
                      <v-checkbox
                        class="ml-3"
                        v-model="performanceGuarantee"
                        @change="performanceGuaranteeChanged"
                        ><template v-slot:label>
                          <div>
                            <p
                              class="
                                text-sm
                                mb-0
                                text-capitalize
                                font-weight-bolder
                              "
                            >
                              Performance Guarantee
                            </p>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item value="financials">
          <v-card
            :disabled="user.claims.user_role == 'customer_view'"
            flat
            id="financials_card"
          >
            <v-card-text>
              <v-form v-if="fields">
                <v-row>
                  <v-col cols="6">
                    <v-card
                      class="card-shadow border-radius-xl mt-4 mb-8"
                    >
                      <div class="pb-0 px-8 pt-4">
                        <div class="d-flex align-items-center">
                          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                            Financial Information
                          </h6>
                        </div>
                      </div>
                      <v-row justify="start">
                        <v-col
                          v-show="
                            user.claims.user_role == 'super_user' ||
                            user.claims.user_role == 'customer_admin'
                          "
                          cols="5"
                          class="ml-12"
                        >
                          <v-autocomplete
                            v-model="billingPlanId"
                            :items="billingPlans"
                            :key="fields['billingPlanId'].value"
                            item-text="name"
                            item-value="id"
                            label="Billing Plan"
                            :allow-overflow="true"
                            @change="billingPlanChange"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row justify start>
                        <v-col
                          v-show="
                            user.claims.user_role == 'super_user' ||
                            user.claims.user_role == 'customer_admin'
                          "
                          cols="3"
                          class="ml-12 mr-n1"
                          v-for="field in [
                            'annualFee',
                            'expDate',
                            'utilityRate',
                          ]"
                          :key="fields[field].value"
                        >
                          <site-detail-input :field="fields[field]" />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-row justify="center">
        <v-dialog v-model="showConfirmModal" persistent max-width="290">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text>Do you really want to remove this site?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="black"
                outlined
                @click="toggleShowConfirmModal(false)"
              >
                Cancel
              </v-btn>
              <v-btn color="error" @click="removeSite()"> Remove </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="fileUploadModal" persistent max-width="450">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text>Select Plan file to upload</v-card-text>
            <div style="padding-left: 5px">
              <input type="file" @change="onFileChange" />
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="black"
                outlined
                @click="toggleShowFileUploadModal(false)"
              >
                Cancel
              </v-btn>
              <v-btn color="error" @click="uploadPlan()"> Upload </v-btn>

              <!-- {{fields['site_plan'].value}} -->
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>
        <v-dialog v-model="historyModal" max-width="650">
          <v-card min-height="300">
            <v-card-title>History</v-card-title>
            <v-divider></v-divider>
              <div class="row">
                <div class="col-12">
                  <ul
                    class="list-group mb-n2 mt-4"
                    style="padding-right: 16px"
                  >
                    <note 
                      v-for="note in historyNotes"
                      :description="note.note"
                      :date="note.date"
                      :id="note.id"
                      :archived="note.is_archived"
                      @on-toggle="toggleNote(note)"
                      @on-delete="deleteNote(note)"
                      @on-edit="editNote(note, $event)"
                      @on-archive="archiveNote(note)"
                    />
                  </ul>
                </div>
              </div>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-alert
          :type="getDataResponseAlertType"
          v-if="showGetDataResponseAlert"
        >
          {{ getDataResponseMessage }}
        </v-alert>
      </v-row>

      <transition name="fade">
        <div class="scrim" v-if="objectPathBeingEdited"></div>
      </transition>
      <new-contact-modal @my-message="newContactCreated" v-bind:siteID="currentSiteId" v-model="showNewContactModal"></new-contact-modal>
    </div>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Service from "@/services/Service.js";
import { siteFields } from "./modules";
import { SiteDetailInput, SiteDetailInverter } from "./components";
import Note from "../../components/Note.vue";
// import SiteMetrics from "./SiteMetrics.vue";
import NewSiteMetrics from "./NewSiteMetrics.vue";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import OpenCases from "../case/OpenCases.vue";
import NewContactModal from "./NewContactModal.vue";

Vue.use(VueToast);

export default {
  name: "SiteDetail",
  components: {
    SiteDetailInput,
    SiteDetailInverter,
    // SiteMetrics,
    NewSiteMetrics,
    NewContactModal,
    Note,
    OpenCases,
  },
  props: {
    siteId: Number,
    insideSiteModal: Boolean,
  },
  data() {
    return {
      refreshDays: 0,
      mask: '###',
      historyNotes: [],
      check_dialog: false,
      pauseCheckValue: null,
      historyModal: false,
      showNewContactModal: false,
      headers: [
        { text: "Name", value: "name"},
        { text: "Last Name", value: "last_name"},
        { text: "Email", value: "email", sortable: false },
        { text: "Phone", value: "phone", sortable: false },
        { text: "Notes", value: "notes", sortable: false },
        { text: "Exclude", value:"exclude_from_report", sortable: false, width: "50px"},
        { text: 'Copy', value: 'actions', sortable: false },
        { text: "Edit", value: "controls", sortable: false },
      ],
      groupHeaders: [
        { text: "Name", value: "name"},
        { text: "ID", value: "id"},
        // { text: "Site Field", value: "site_field", sortable: false },
        // { text: "Report", value: "report"},
      ],
      items: [],
      groupItems: [],
      dateKey: 0,
      tab: null,
      fields: {},
      isArchived: false,
      isPaused: false,
      annualVisit: false,
      performanceGuarantee: false,
      managingCases: false,
      siteName: null,
      fieldLists: null,
      siteGroups: null,
      gridData: null,
      uploadFile: null,
      dataLoaded: false,
      showConfirmModal: false,
      fileUploadModal: false,
      showGetDataResponseAlert: false,
      getDataResponseAlertType: null,
      getDataResponseMessage: "",
      notes: [],
      customer_notes: [],
      newNote: "",
      reportingGroupId: null,
      billingPlanId: null,
      reportingGroups: [],
      billingPlans: [],
      currentSiteId: null,
    };
  },
  computed: {
    user: function () {
      return this.$store.getters.currentUser;
    },
    currentSiteInverterIds() {
      return this.currentSiteInverters.map((inverter) => inverter.id);
    },
    numberOfInverters() {
      return this.currentSiteInverters.length;
    },
    dCSizeOfInverters() {
      return this.currentSiteInverters
        .reduce((acc, { dCSize }) => {
          return (acc += Number(dCSize));
        }, 0)
        .toFixed(2);
    },
    aCSizeOfInverters() {
      return this.currentSiteInverters
        .reduce((acc, { aCSize }) => {
          return (acc += Number(aCSize));
        }, 0)
        .toFixed(2);
    },
    ...mapState({
      currentSite: (state) => state.sites.currentSite,
      currentSiteInverters: (state) => state.sites.currentSiteInverters,
      objectPathBeingEdited: (state) => state.sites.objectPathBeingEdited,
      valueBeingEdited: (state) => state.sites.valueBeingEdited,
      inverterJustDeleted: (state) => state.sites.inverterJustDeleted,
    }),
  },
  /*beforeRouteUpdate (to, from) {
    //feature/580
    this.siteId = to.params.id;
    this.loadSite();
  },*/
  beforeMount() {
    this.loadSite();
    this.getContactData();
    this.getSiteName();
    this.getReportData();
  },
  created() {
    window.addEventListener("mousedown", this.windowMousedownEventListener);
  },
  beforeDestroy() {
    window.removeEventListener("mousedown", this.windowMousedownEventListener);
  },
  updated() {
    var title = this.siteName
    document.title = title + ' Details'
  },
  watch: {
    "$route.params.id": {
      handler: function (value) {
        this.siteId = value;
        this.loadSite();
        //this.$router.go(0);
      },
      deep: true,
      //immediate: true,
    },
    inverterJustDeleted(val) {
      if (val) {
        this.loadSite();

        this.setInverterJustDeleted({
          value: false,
        });
      }
    },
  },
  methods: {
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.$toast.success('Copied to clipboard.'); // Assuming you have a toast system for notifications
        }).catch(err => {
          console.error('Failed to copy content: ', err);
        });
      } else {
        // Fallback for older browsers
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
    },
    onRefreshDaysInput(value) {
      let params = {
        refreshDays: value,
      };
      console.log(value);
      Service.updateSite(this.currentSite.id, params)
        .then((response) => {
          console.log("refreshDays updated");
          Vue.$toast.success(`Settings saved.`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          console.error("Error updating site data:", error.response);
        });
    },
    getSiteName: 
    function() {
      NProgress.start();
      var site_id = parseInt(this.currentSiteId)
      Service.getSite(site_id)
        .then((response) => {
          NProgress.done();
          this.siteName = response.data.name
          this.$forceUpdate();
          // const instance = getCurrentInstance();
          // instance.proxy.forceUpdate();
        })
        .catch((error) => {
          NProgress.done();
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        })
    },
    getContactData() {
      Service.getContactRelationBySite(this.currentSiteId)
        .then((response) => {
          this.items = response.data
          console.log(this.items)
        })
        .catch((error) => {
          console.log(error)
        });
    },
    getReportData() {
      Service.getAllRptSiteGroupsById(this.currentSiteId)
        .then((response) => {
          this.groupItems = response.data.groups;
        })
        .catch((error) => {
          Vue.$toast.error(`Error report lists`, { position: "top-right" });
          console.log(error);
          NProgress.done();
        });
    },
    newContactCreated() {
      this.showNewContactModal = false;
      this.getContactData()
      Vue.$toast.success(`New Contact Created`, {
        position: "top-right",
        duration: 4000,
      });
    },
    reportExclude(e, item) {
      let params = {
        exclude_from_report: e
      }
      Service.excludeFromReport(item.id, params)
        .then((response) => {
          console.log(response)
          this.getContactData()
        })
        .catch((error) => {
          Vue.$toast.error(`Error excluding contact`, { position: "top-right" });
          console.log(error);
        });
    },
    deleteContact(item) {
      Service.deleteContactRelationshipBySite(this.currentSiteId, item.id)
        .then((response) => {
          this.getContactData()
        })
        .catch((error) => {
          Vue.$toast.error(`Error deleting relationship`, { position: "top-right" });
          console.log(error);
        })
    },
    startNewContact() {
      this.showNewContactModal = true;
    },
    clickInverterTab() {
      this.dateKey += 1;
      // console.log("click inverter tab")
      // console.log(this.currentSiteInverters);
      // this.$refs.siteDetailInverter.reloadInvData();
    },
    isArchivedChanged(newValue) {
      let params = {
        isArchived: newValue,
      };
      Service.updateSite(this.currentSite.id, params)
        .then((response) => {
          if (newValue == true) {
            Service.getCasesBySiteId(this.currentSite.id)
              .then((response) => {
                const cases = response.data
                const end = this.$moment().subtract(1, "days");
                const endDate = end.format("YYYY-MM-DD");
                cases.forEach((item) => {
                  if (item.case_status.id == 3) {
                    console.log('Case previously closed')
                  } else {
                    let params = {
                    case_status: {
                      id: 3,
                      name: "Done"
                    },
                    case_status_id: 3,
                    date_closed: endDate
                  };
                  Service.updateCase(item.id, params)
                    .then((response) => {
                      console.log("flag isArchived updated and case closed");
                      Vue.$toast.success(`Settings saved.`, {
                        position: "top-right",
                        duration: 4000,
                      });
                    })
                    .catch((error) => {
                      console.error("Error updating case data:", error.response);
                    });
                  }
                })
              })
              .catch((error) => {
                console.error("Error getting cases by site", error.response)
              });
          }
          else {
            console.log("flag isArchived updated");
            Vue.$toast.success(`Settings saved.`, {
              position: "top-right",
              duration: 4000,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating site data:", error.response);
        });
    },
    cancelPause() {
      this.pauseCheckValue = null;
      this.loadSite();
      this.check_dialog = false;
    },
    isPausedChecked(newValue) {
      this.pauseCheckValue = newValue;
      this.check_dialog = true;
    },
    isPausedChanged() {
      let params = {
        isPaused: this.pauseCheckValue,
      };
      this.check_dialog = false;
      Service.updateSite(this.currentSite.id, params)
        .then((response) => {
          console.log("flag isPaused updated");
          Vue.$toast.success(`Settings saved.`, {
            position: "top-right",
            duration: 4000,
          });
          this.pauseCheckValue = null;
          this.loadSite();
        })
        .catch((error) => {
          console.error("Error updating site data:", error.response);
        });
    },
    managingCasesChanged(newValue) {
      let params = {
        managingCases: newValue,
      };
      console.log(newValue);
      Service.updateSite(this.currentSite.id, params)
        .then((response) => {
          console.log("flag managingCases updated");
          this.resetCache();
          Vue.$toast.success(`Settings saved.`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          console.error("Error updating site data:", error.response);
        });
    },
    annualVisitChanged(newValue) {
      let params = {
        annualVisit: newValue,
      };
      console.log(newValue);
      Service.updateSite(this.currentSite.id, params)
        .then((response) => {
          console.log("flag annualVisit updated");
          Vue.$toast.success(`Settings saved.`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          console.error("Error updating site data:", error.response);
        });
    },
    performanceGuaranteeChanged(newValue) {
      let params = {
        performanceGuarantee: newValue,
      };
      console.log(newValue);
      Service.updateSite(this.currentSite.id, params)
        .then((response) => {
          console.log("flag performance guarantee updated");
          Vue.$toast.success(`Settings saved.`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          console.error("Error updating site data:", error.response);
        });
    },
    resetCache() {
      Service.resetCache()
        .then((response) => {
          Vue.$toast.success(`Cache Reset`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error resetting cache`, { position: "top-right" });
          console.error(error);
        });
    },
    loadSite() {
      if (this.siteId) {
        this.currentSiteId = this.siteId;
      } else {
        this.currentSiteId = this.$route.params.id;
      }

      Service.getSiteWithInvDetail(this.currentSiteId)
        .then((response) => {
          const siteWithoutInverters = response.data;
          let inverters = [...siteWithoutInverters.inverters];
          delete siteWithoutInverters.inverters;
          this.isArchived = siteWithoutInverters.isArchived;
          this.isPaused = siteWithoutInverters.isPaused;
          // ensure all inverter ids are strings
          inverters = inverters.map((inverter) => {
            const newInverter = Object.assign({}, inverter);
            newInverter.id = newInverter.id.toString();

            return newInverter;
          });

          inverters = inverters.sort((a, b) => a.id - b.id);

          this.setCurrentSite({
            value: siteWithoutInverters,
          });

          this.setCurrentSiteInverters({
            operation: "loadData",
            value: inverters,
          });

          this.createSiteFields();

          this.loadNotes(this.currentSite.id);
          this.loadCustomerNotes(this.currentSite.id);

          this.loadReportingGroups(this.currentSite.reportingGroupId);
          this.loadBillingPlans(this.currentSite.billingPlanId);

          this.annualVisit = this.currentSite.annualVisit;
          this.refreshDays = this.currentSite.refreshDays;
          this.performanceGuarantee = this.currentSite.performanceGuarantee;
          this.managingCases = this.currentSite.managingCases;

          this.dataLoaded = true;
        })
        .catch((error) => {
          console.error("Error getting sites data:", error.response);
        });
    },
    toggleShowConfirmModal(show) {
      this.showConfirmModal = show;
    },
    toggleShowFileUploadModal(show) {
      this.fileUploadModal = show;
    },
    removeSite() {
      this.showConfirmModal = false;
      Service.deleteSite(this.currentSite.id)
        .then((response) => {
          this.$router.push({
            path: "/sites",
          });
        })
        .catch((e) => {
          console.error("There was a problem deleting this site:", e);
        });
    },
    createSiteFields() {
      this.fields = siteFields;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      console.log(files[0]);
      this.uploadFile = files[0];
      // this.createImage(files[0]);
    },
    uploadPlan() {
      var site_id = this.$store.state.sites.currentSite.id;
      Service.uploadSitePlan(this.uploadFile, site_id)
        .then((response) => {
          console.log(response);
          // reload site
          this.loadSite();
          console.log(this.$store.state.sites.currentSite.site_plan);
          console.log(this.currentSite);
          this.toggleShowFileUploadModal(false);
        })
        .catch((e) => {
          console.error("There was an error uploading the file:", e);
        });
    },
    windowMousedownEventListener(e) {
      if (e.target.id !== `${this.objectPathBeingEdited}-edit-icon`) {
        e.preventDefault();
      }
    },
    addInverter() {
      Service.createInverter(this.currentSite.id)
        .then((response) => {
          response.data.id = response.data.id.toString();

          this.setCurrentSiteInverters({
            operation: "add",
            newInverter: response.data,
          });
        })
        .catch((e) => {
          console.error("There was an error adding the inverter:", e);
        });
    },
    deleteInverter(inverterId) {
      console.log("deleteInverter method called...");
      console.log(inverterId);
      Service.deleteInverter(this.currentSite.id, inverterId)
        .then((response) => {
          // this.setInverterJustDeleted({
          //   value: true,
          // });
          this.loadSite();
          console.log(response.data);
        })
        .catch((e) => {
          console.error("There was a problem deleting this inverter:", e);
        });
    },
    openWindow: function (link) {
      window.open(link, "_blank");
    },
    showHistoryModal() {
      this.historyModal = true;
      this.loadArchived(this.currentSite.id);
    },
    backloadData(days) {
      const end = this.$moment().subtract(1, "days");
      const start = this.$moment().subtract(1 + days, "days");

      const startDate = start.format("YYYY-MM-DD");
      const endDate = end.format("YYYY-MM-DD");

      const params = {
        startDate,
        endDate,
      };

      Service.getSiteData(this.currentSite.id, params)
        .then((response) => {
          if (response.status === 200) {
            this.getDataResponseAlertType = "success";
            this.getDataResponseMessage =
              "Site data was loaded. Please allow a few minutes to see results";
            this.showGetDataResponseAlert = true;

            setTimeout(() => {
              this.getDataResponseAlertType = null;
              this.getDataResponseMessage = "";
              this.showGetDataResponseAlert = false;
            }, 2000);
          } else {
            throw new Error();
          }
        })
        .catch((e) => {
          this.getDataResponseAlertType = "error";
          this.getDataResponseMessage =
            "There was a problem loading site data.";
          this.showGetDataResponseAlert = true;

          setTimeout(() => {
            this.getDataResponseAlertType = null;
            this.getDataResponseMessage = "";
            this.showGetDataResponseAlert = false;
          }, 2000);
        });
    },
    ...mapMutations([
      "setCurrentSite",
      "setCurrentSiteInverters",
      "setInverterJustDeleted",
    ]),
    loadNotes(site_id) {
      Service.getNotes(site_id)
        .then((response) => {
          this.notes = response.data;
        })
        .catch((error) => {
          console.log("Error getting sites data:", error.response);
        });
    },
    loadCustomerNotes(site_id) {
      Service.getCustomerNotes(site_id)
        .then((response) => {
          this.customer_notes = response.data;
        })
        .catch((error) => {
          console.log("Error getting data:", error.response);
        });
    },
    addNote() {
      var params = {
        site_id: this.currentSite.id,
        note: this.newNote,
      };
      Service.createNote(this.currentSite.id, params)
        .then((response) => {
          return Service.getNotes(this.currentSite.id);
        })
        .then((response) => {
          this.notes = response.data;
        })
        .catch((error) => {
          console.log("Error creating site:", error.response);
        });
      this.newNote = "";
    },
    deleteNote(deletedNote) {
      Service.deleteNote(this.currentSite.id, deletedNote.id)
        .then((response) => {
          return Service.getNotes(this.currentSite.id);
        })
        .then((response) => {
          this.notes = response.data;
          return Service.getCustomerNotes(this.currentSite.id);
        })
        .then((response) => {
          this.customer_notes = response.data;
        })
        .catch((error) => {
          console.log("Error creating site:", error.response);
        });
    },
    editNote(note, newNoteDescription) {
      var params = {
        site_id: this.currentSite.id,
        id: note.id,
        note: newNoteDescription,
      };
      Service.updateNote(this.currentSite.id, note.id, params)
        .then((response) => {
          return Service.getNotes(this.currentSite.id);
        })
        .then((response) => {
          this.notes = response.data;
          return Service.getCustomerNotes(this.currentSite.id);
        })
        .then((response) => {
          this.customer_notes = response.data;
        })
        .catch((error) => {
          console.log("Error updating note:", error.response);
        });
    },
    archiveNote(note) {
      var params = {
        site_id: this.currentSite.id,
        id: note.id,
        is_archived: true
      };
      Service.archiveNote(this.currentSite.id, note.id, params)
        .then((response) => {
          return Service.getNotes(this.currentSite.id);
        })
        .then((response) => {
          this.notes = response.data;
        })
        .catch((error) => {
          console.log("Error archiving note:", error.response);
        });
    },
    loadArchived(site_id) {
      Service.getArchived(site_id)
        .then((response) => {
          this.historyNotes = response.data;
        })
        .catch((error) => {
          console.log("Error getting sites data:", error.response);
        });
    },
    loadReportingGroups(reporting_group_id) {
      Service.getReportingGroups()
        .then((response) => {
          this.reportingGroups = response.data;
          this.reportingGroupId = reporting_group_id;
        })
        .catch((error) => {
          console.log("Error getting reporting groups:", error.response);
        });
    },
    loadBillingPlans(billing_plan_id) {
      Service.getBillingPlans()
        .then((response) => {
          this.billingPlans = response.data;
          this.billingPlanId = billing_plan_id;
        })
        .catch((error) => {
          console.log("Error getting billing plans:", error.response);
        });
    },
    reportingGroupChange(newValue) {
      let params = {
        reportingGroupId: newValue,
      };
      Service.updateSite(this.currentSite.id, params)
        .then((response) => {
          Vue.$toast.success(`Settings saved.`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          console.error("Error updating site data:", error.response);
        });
    },
    billingPlanChange(newValue) {
      let params = {
        billingPlanId: newValue,
      };
      Service.updateSite(this.currentSite.id, params)
        .then((response) => {
          Vue.$toast.success(`Settings saved.`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          console.error("Error updating site data:", error.response);
        });
    },
  },
};
</script>

<style>
.theme--light.v-tabs > .v-tabs-bar {
  background-color: #f0f2f5;
}
.v-input--checkbox {
  margin-top: 0 !important;
}
.site-detail {
  background-color: #f0f2f5;
}
.theme--light.v-tabs-items {
  background-color: #f0f2f5;
}
#metrics_card {
  background-color: #f0f2f5;
}
#info_card {
  background-color: #f0f2f5;
}
#financials_card {
  background-color: #f0f2f5;
}
#additional_card {
  background-color: #f0f2f5;
}
#page_header {
  background-color: #f0f2f5;
}
.white-field input {
  color: white !important;
}
.white-field label {
  color: white !important;
}
.white-field > .v-input__control > .v-input__slot:before {
  color: white !important;
}
#siteName {
  margin: 0;
  display: inline-block;
}
#sites_btn {
  margin-left: 1%;
}
#inverters_btn {
  margin-left: 1%;
}
#nativeLink_btn {
  margin-left: 2%;
}
#pageTabs {
  padding-top: 1em;
}
</style>