const shades = [
  {
    value: 'low',
    text: 'Low',
  },
  {
    value: 'mediumLow',
    text: 'Medium Low',
  },
  {
    value: 'medium',
    text: 'Medium',
  },
  {
    value: 'mediumHigh',
    text: 'Medium High',
  },
  {
    value: 'high',
    text: 'High',
  },
];

const monitoringSystems = [
  {
    value: 'solaredge',
    text: 'SolarEdge'
  },
  {
    value: 'fronius',
    text: 'Fronius'
  },
  {
    value: 'locus',
    text: 'Locus Energy'
  },
  {
    value: 'solarlog',
    text: 'Solarlog'
  },
  {
    value: 'also',
    text: 'Also Energy'
  },
  {
    value: 'powerdash',
    text: 'Powerdash'
  },
  {
    value: 'powerfactors',
    text: 'Powerfactors'
  },
  {
    value: 'smasunnyportal',
    text: 'SMA'
  },
  {
    value: 'gmp',
    text: 'GMP'
  },
  {
    value: 'enphase',
    text: 'Enphase'
  },
  {
    value: 'allearth',
    text: 'Allearth',
  },
  {
    value: 'auroravision',
    text: 'Auroravision',
  },
  {
    value: 'chint',
    text: 'Chint',
  },
  {
    value: 'egauge',
    text: 'Egauge',
  },
  {
    value: 'pika',
    text: 'Pika',
  },
  {
    value: 'smasunnyportal',
    text: 'Smasunnyportal',
  },
  {
    value: 'solectria',
    text: 'Solectria',
  }
];

const inverterManufacturers = [
  {
    value: 'SolarEdge',
    text: 'SolarEdge'
  },
  {
    value: 'SMA',
    text: 'SMA'
  },
  {
    value: 'Sunpower',
    text: 'Sunpower'
  },
  {
    value: 'Enphase',
    text: 'Enphase'
  },
  {
    value: 'Fronius',
    text: 'Fronius'
  },
  {
    value: 'Chint',
    text: 'Chint'
  },
  {
    value: 'Solectria',
    text: 'Solectria'
  },
];

const checkFrequencies = [
  {
    value: 'daily',
    text: 'Daily',
  },
  {
    value: 'weekly',
    text: 'Weekly',
  },
  {
    value: 'monthly',
    text: 'Monthly',
  },
];

export const siteFields = {
  name: {
    objectPath: 'name',
    component: 'v-text-field',
    required: true,
    label: 'Site',
  },
  customerName: {
    objectPath: 'customerName',
    component: 'v-text-field',
    required: true,
    label: 'Solar Company',
  },
  customerAccountName: {
    objectPath: 'customerAccountName',
    component: 'v-text-field',
    required: true,
    label: 'Account',
  },
  checkFrequency: {
    objectPath: 'checkFrequency',
    component: 'v-select',
    required: false,
    label: 'Check Frequency',
    items: checkFrequencies,
  },
  street: {
    objectPath: 'address.street',
    component: 'v-text-field',
    required: true,
    label: 'Street',
  },
  city: {
    objectPath: 'address.city',
    component: 'v-text-field',
    required: true,
    label: 'City',
  },
  state: {
    objectPath: 'address.state',
    component: 'v-text-field',
    required: true,
    label: 'State',
  },
  postalCode: {
    objectPath: 'address.postalCode',
    component: 'v-text-field',
    required: true,
    label: 'Zip Code',
  },
  email: {
    objectPath: 'email',
    component: 'v-text-field',
    type: 'email',
    required: true,
    label: 'Email',
  },
  phone: {
    objectPath: 'phone',
    component: 'v-text-field',
    required: true,
    label: 'Phone',
  },
  monitoringSystem: {
    objectPath: 'monitoringSystem',
    component: 'v-select',
    required: true,
    label: 'Monitoring System',
    items: monitoringSystems,
  },
  installYear: {
    objectPath: 'installYear',
    component: 'v-text-field',
    required: true,
    type: 'number',
    decimalPlaces: 0,
    label: 'Install Year',
  },
  inverterManufacturer: {
    objectPath: 'inverterManufacturer',
    component: 'v-text-field',
    label: 'Inverter Manufacturer'
  },
  solarModuleWattage: {
    objectPath: 'solarModuleWattage',
    component: 'v-text-field',
    type: 'number',
    decimalPlaces: 0,
    label: 'Solar Module Wattage',
  },
  shadeFallSpring: {
    objectPath: 'shade.fallSpring',
    component: 'v-select',
    label: 'Fall/Spring',
    items: shades,
    itemsComputedType: 'shades',
  },
  shadeSummer: {
    objectPath: 'shade.summer',
    component: 'v-select',
    label: 'Summer',
    items: shades,
    itemsComputedType: 'shades',
  },
  shadeWinter: {
    objectPath: 'shade.winter',
    component: 'v-select',
    label: 'Winter',
    items: shades,
    itemsComputedType: 'shades',
  },
  monitoringSiteId: {
    objectPath: 'monitoringSiteId',
    component: 'v-text-field',
    label: 'Monitoring Site Id',
  },
  monitoringApiKey: {
    objectPath: 'monitoringApiKey',
    component: 'v-text-field',
    label: 'Monitoring API key',
  },
  nativeMonitoringLink: {
    objectPath: 'nativeMonitoringLink',
    component: 'v-text-field',
    label: 'Native Monitoring Portal URL',
  },
  sitePlans: {
    objectPath: 'sitePlans',
    component: 'v-text-field',
    required: true,
    label: 'Site Plans',
  },
  annualVisit: {
    objectPath: 'annualVisit',
    component: 'v-text-field',
    label: 'Annual visit'
  },
  component2: {
    objectPath: 'component2',
    component: 'v-text-field',
    label: 'Other Components (Inverter Type and Qty)'
  },
  solarModule: {
    objectPath: 'solarModule',
    component: 'v-text-field',
    label: 'Customer Internal ID'
  },
  secondaryMonitoringSystem: {
    objectPath: 'secondaryMonitoringSystem',
    component: 'v-text-field',
    label: 'Secondary Monitoring System'
  },
  annualProductionEstimate: {
    objectPath: 'annualProductionEstimate',
    component: 'v-text-field',
    type: 'number',
    decimalPlaces: 1,
    label: 'Annual Production Estimate'
  },
  reportingGroupId: {
    objectPath: 'reportingGroupId',
    component: 'v-text-field',
    decimalPlaces: 0,
    label: 'Reporting Group'
  },
  billingPlanId: {
    objectPath: 'billingPlanId',
    component: 'v-text-field',
    decimalPlaces: 0,
    label: 'Billing Plan'
  },
  annualFee: {
    objectPath: 'annualFee',
    component: 'v-text-field',
    type: 'number',
    decimalPlaces: 2,
    label: 'Annual Fee'
  },
  expDate: {
    objectPath: 'expDate',
    component: 'v-text-field',
    type: 'date',
    label: 'Expiration Date'
  },
  utilityRate: {
    objectPath: 'utilityRate',
    component: 'v-text-field',
    type: 'number',
    decimalPlaces: 2,
    label: 'Utility Rate'
  }
};
