var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "px-6 py-6", attrs: { fluid: "" } }, [
    _vm.dataLoaded
      ? _c(
          "div",
          { staticClass: "site-detail rounded", attrs: { id: "page_header" } },
          [
            _vm.insideSiteModal
              ? _c("h2", { attrs: { id: "siteName" } }, [
                  _c("small", [_vm._v(_vm._s(this.currentSite.name))]),
                ])
              : _c("h2", [
                  _c("small", [_vm._v(_vm._s(this.currentSite.name))]),
                ]),
            _vm.insideSiteModal
              ? _c(
                  "v-btn",
                  {
                    staticClass:
                      "font-weight-bold text-xs btn-default btn-outline-default",
                    attrs: {
                      ripple: false,
                      elevation: 0,
                      color: "transparent",
                      id: "nativeLink_btn",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openWindow(
                          _vm.currentSite.nativeMonitoringLink
                        )
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/" +
                          this.currentSite.monitoringSystem +
                          ".png"),
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm.currentSite.sitePlans && _vm.insideSiteModal
              ? _c(
                  "v-btn",
                  {
                    staticClass:
                      "font-weight-bold text-xs btn-default bg-gradient-default",
                    attrs: { ripple: false, elevation: 0, id: "sites_btn" },
                    on: {
                      click: function ($event) {
                        return _vm.openWindow(_vm.currentSite.sitePlans)
                      },
                    },
                  },
                  [_vm._v(" Site Plans ")]
                )
              : _vm._e(),
            _vm.insideSiteModal
              ? _c(
                  "v-btn",
                  {
                    staticClass:
                      "font-weight-bold text-xs btn-default bg-gradient-default",
                    attrs: { ripple: false, elevation: 0, id: "inverters_btn" },
                    on: {
                      click: function ($event) {
                        return _vm.openWindow(
                          "/sites/" + _vm.currentSite.id + "/inverters_list"
                        )
                      },
                    },
                  },
                  [_vm._v(" Inverters ")]
                )
              : _vm._e(),
            _c(
              "v-tabs",
              {
                attrs: { id: "pageTabs", grow: "" },
                model: {
                  value: _vm.tab,
                  callback: function ($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab",
                },
              },
              [
                !_vm.insideSiteModal
                  ? _c("v-tab", { attrs: { href: "#metrics" } }, [
                      _vm._v(" Metrics "),
                    ])
                  : _vm._e(),
                _c("v-tab", { attrs: { href: "#info" } }, [_vm._v(" Info ")]),
                _c("v-tab", { attrs: { href: "#additional-info" } }, [
                  _vm._v(" Additional Info "),
                ]),
                _c(
                  "v-tab",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.user.claims.user_role == "super_user" ||
                          _vm.user.claims.user_role == "customer_admin",
                        expression:
                          "\n          user.claims.user_role == 'super_user' ||\n          user.claims.user_role == 'customer_admin'\n        ",
                      },
                    ],
                    attrs: { href: "#financials" },
                  },
                  [_vm._v(" Financials ")]
                ),
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                staticClass: "mb-6",
                attrs: { id: "metrics_background" },
                model: {
                  value: _vm.tab,
                  callback: function ($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab",
                },
              },
              [
                _c(
                  "v-tab-item",
                  { attrs: { value: "metrics" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "card-shadow",
                        attrs: { flat: "", id: "metrics_card" },
                      },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c("v-row", { attrs: { justify: "end" } }),
                            _c("v-row", { attrs: { justify: "end" } }),
                            _c("new-site-metrics", {
                              attrs: {
                                site_id: this.currentSite.id,
                                nativeLink:
                                  this.currentSite.nativeMonitoringLink,
                                system: this.currentSite.monitoringSystem,
                                plans: this.currentSite.sitePlans,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { attrs: { value: "info" } },
                  [
                    _c(
                      "v-card",
                      {
                        attrs: {
                          disabled:
                            _vm.user.claims.user_role == "customer_view",
                          flat: "",
                          id: "info_card",
                        },
                      },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-row",
                              { staticStyle: { padding: "10px" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "8" } },
                                  [
                                    _vm.fields
                                      ? _c(
                                          "v-form",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "card-shadow border-radius-xl mt-4 mb-4 mr-4",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pb-0 px-4 pt-4",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass:
                                                              "text-h6 text-typo mb-4 font-weight-bold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " General Information "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { justify: "start" },
                                                  },
                                                  _vm._l(
                                                    ["name", "customerName"],
                                                    function (field) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: _vm.fields[field]
                                                            .value,
                                                          staticClass:
                                                            "ml-11 mr-6",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _c(
                                                            "site-detail-input",
                                                            {
                                                              attrs: {
                                                                field:
                                                                  _vm.fields[
                                                                    field
                                                                  ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { justify: "start" },
                                                  },
                                                  _vm._l(
                                                    [
                                                      "customerAccountName",
                                                      "checkFrequency",
                                                    ],
                                                    function (field) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: _vm.fields[field]
                                                            .value,
                                                          staticClass:
                                                            "ml-11 mr-6",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _c(
                                                            "site-detail-input",
                                                            {
                                                              attrs: {
                                                                field:
                                                                  _vm.fields[
                                                                    field
                                                                  ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pb-0 px-4 pt-4",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass:
                                                              "text-h6 text-typo mb-4 font-weight-bold",
                                                          },
                                                          [_vm._v(" Address ")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { justify: "start" },
                                                  },
                                                  _vm._l(
                                                    ["street", "city"],
                                                    function (field) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: _vm.fields[field]
                                                            .value,
                                                          staticClass:
                                                            "ml-11 mr-6",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _c(
                                                            "site-detail-input",
                                                            {
                                                              attrs: {
                                                                field:
                                                                  _vm.fields[
                                                                    field
                                                                  ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { justify: "start" },
                                                  },
                                                  _vm._l(
                                                    ["state", "postalCode"],
                                                    function (field) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: _vm.fields[field]
                                                            .value,
                                                          staticClass:
                                                            "ml-11 mr-6",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _c(
                                                            "site-detail-input",
                                                            {
                                                              attrs: {
                                                                field:
                                                                  _vm.fields[
                                                                    field
                                                                  ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pb-0 px-4 pt-4",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass:
                                                              "text-h6 text-typo mb-4 font-weight-bold",
                                                          },
                                                          [_vm._v(" Contact ")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { justify: "start" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "ml-12 mr-n1",
                                                        attrs: { cols: "10" },
                                                      },
                                                      [
                                                        _c("v-data-table", {
                                                          staticClass:
                                                            "elevation-1",
                                                          attrs: {
                                                            headers:
                                                              _vm.headers,
                                                            items: _vm.items,
                                                            "items-per-page": 5,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "top",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-toolbar",
                                                                      {
                                                                        attrs: {
                                                                          flat: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "\n                                font-weight-bold\n                                text-xs\n                                btn-default\n                                bg-gradient-default",
                                                                            attrs:
                                                                              {
                                                                                disable:
                                                                                  _vm
                                                                                    .user
                                                                                    .claims
                                                                                    .user_role ==
                                                                                  "customer_view",
                                                                                ripple: false,
                                                                                elevation: 0,
                                                                                id: "newUser_btn",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                _vm.startNewContact,
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Add Contact "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "item.exclude_from_report",
                                                                fn: function (
                                                                  props
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "padding-top":
                                                                              "10px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-checkbox",
                                                                          {
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  e
                                                                                ) {
                                                                                  return _vm.reportExclude(
                                                                                    e,
                                                                                    props.item
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  props
                                                                                    .item
                                                                                    .exclude_from_report,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      props.item,
                                                                                      "exclude_from_report",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "props.item.exclude_from_report",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "item.actions",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var item =
                                                                    ref.item
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "mx-2",
                                                                        attrs: {
                                                                          icon: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.copyToClipboard(
                                                                                item.name +
                                                                                  " " +
                                                                                  item.last_name +
                                                                                  " " +
                                                                                  item.email +
                                                                                  " " +
                                                                                  item.phone
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                size: "18",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-content-copy"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "item.controls",
                                                                fn: function (
                                                                  props
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "mx-2",
                                                                        attrs: {
                                                                          icon: "",
                                                                          color:
                                                                            "red",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteContact(
                                                                                props.item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-delete"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2031198502
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pb-0 px-4 pt-4",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass:
                                                              "text-h6 text-typo mb-4 font-weight-bold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Reporting Groups "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { justify: "start" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "ml-12 mr-n1",
                                                        attrs: { cols: "10" },
                                                      },
                                                      [
                                                        _c("v-data-table", {
                                                          staticClass:
                                                            "elevation-1",
                                                          attrs: {
                                                            headers:
                                                              _vm.groupHeaders,
                                                            items:
                                                              _vm.groupItems,
                                                            "items-per-page": 5,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pb-0 px-4 pt-4",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass:
                                                              "text-h6 text-typo mb-4 font-weight-bold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Monitoring "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { justify: "start" },
                                                  },
                                                  _vm._l(
                                                    [
                                                      "monitoringSystem",
                                                      "installYear",
                                                    ],
                                                    function (field) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: _vm.fields[field]
                                                            .value,
                                                          staticClass:
                                                            "ml-11 mr-6",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _c(
                                                            "site-detail-input",
                                                            {
                                                              attrs: {
                                                                field:
                                                                  _vm.fields[
                                                                    field
                                                                  ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pb-0 px-4 pt-4",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass:
                                                              "text-h6 text-typo mb-4 font-weight-bold",
                                                          },
                                                          [_vm._v(" Settings ")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { justify: "start" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "ml-4",
                                                        attrs: { cols: "3" },
                                                      },
                                                      [
                                                        _c("v-checkbox", {
                                                          staticClass: "ml-6",
                                                          on: {
                                                            change:
                                                              _vm.isArchivedChanged,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "label",
                                                                fn: function () {
                                                                  return [
                                                                    _c("div", [
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "\n                                  text-sm\n                                  mb-0\n                                  text-capitalize\n                                  font-weight-bolder\n                                ",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Archived "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            1002768763
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.isArchived,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.isArchived =
                                                                $$v
                                                            },
                                                            expression:
                                                              "isArchived",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "3" } },
                                                      [
                                                        _c("v-checkbox", {
                                                          staticClass: "ml-3",
                                                          on: {
                                                            change:
                                                              _vm.isPausedChecked,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "label",
                                                                fn: function () {
                                                                  return [
                                                                    _c("div", [
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "\n                                  text-sm\n                                  mb-0\n                                  text-capitalize\n                                  font-weight-bolder\n                                ",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Paused "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2372927339
                                                          ),
                                                          model: {
                                                            value: _vm.isPaused,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.isPaused = $$v
                                                            },
                                                            expression:
                                                              "isPaused",
                                                          },
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "v-dialog",
                                                              {
                                                                attrs: {
                                                                  width: "500",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.check_dialog,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.check_dialog =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "check_dialog",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  [
                                                                    _c(
                                                                      "v-card-title",
                                                                      {
                                                                        staticClass:
                                                                          "text-h5 grey lighten-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Pausing/Unpausing Site "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "text-h8 mt-4",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Are you sure? **Triple check** if there is a case open related to this before changing. This will be paused until it's manually undone. "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-divider"
                                                                    ),
                                                                    _c(
                                                                      "v-card-actions",
                                                                      [
                                                                        _c(
                                                                          "v-spacer"
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "\n                                    font-weight-bold\n                                    text-xs\n                                    btn-danger\n                                    bg-gradient-danger\n                                  ",
                                                                            on: {
                                                                              click:
                                                                                _vm.cancelPause,
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Cancel "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "\n                                    font-weight-bold\n                                    text-xs\n                                    btn-success\n                                    bg-gradient-success\n                                  ",
                                                                            on: {
                                                                              click:
                                                                                _vm.isPausedChanged,
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Continue "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pb-0 px-4 pt-4",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass:
                                                              "text-h6 text-typo mb-4 font-weight-bold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Refresh Settings "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { justify: "start" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "ml-4",
                                                        attrs: { cols: "3" },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass: "mr-4",
                                                          staticStyle: {
                                                            "max-width":
                                                              "120px",
                                                          },
                                                          attrs: {
                                                            mask: _vm.mask,
                                                            label:
                                                              "Days to Refresh",
                                                            outlined: "",
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.onRefreshDaysInput,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.refreshDays,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.refreshDays =
                                                                $$v
                                                            },
                                                            expression:
                                                              "refreshDays",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "mt-4", attrs: { cols: "4" } },
                                  [
                                    _c("v-card", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "bg-gradient-primary shadow-primary",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pb-0 px-4 pt-4" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "\n                            text-white text-h6 text-typo\n                            mb-4\n                            font-weight-bold\n                          ",
                                                    },
                                                    [_vm._v(" Add Notes ")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mt-n7 mb-2" },
                                            [
                                              _c(
                                                "form",
                                                {
                                                  staticClass: "ml-6 col-10",
                                                  on: {
                                                    submit: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.addNote()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "white-field",
                                                    attrs: {
                                                      label:
                                                        "Create a new note...",
                                                      color: "white",
                                                    },
                                                    model: {
                                                      value: _vm.newNote,
                                                      callback: function ($$v) {
                                                        _vm.newNote = $$v
                                                      },
                                                      expression: "newNote",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("v-card", { staticClass: "mt-6" }, [
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "pb-0 px-4 pt-4" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "text-h6 text-typo mb-4 font-weight-bold",
                                                  },
                                                  [_vm._v(" Notes ")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "ul",
                                              {
                                                staticClass: "list-group mb-n2",
                                                staticStyle: {
                                                  "padding-left": "16px",
                                                  "padding-right": "10px",
                                                },
                                              },
                                              _vm._l(
                                                _vm.notes,
                                                function (note) {
                                                  return _c("note", {
                                                    attrs: {
                                                      description: note.note,
                                                      date: note.date,
                                                      id: note.id,
                                                    },
                                                    on: {
                                                      "on-toggle": function (
                                                        $event
                                                      ) {
                                                        return _vm.toggleNote(
                                                          note
                                                        )
                                                      },
                                                      "on-delete": function (
                                                        $event
                                                      ) {
                                                        return _vm.deleteNote(
                                                          note
                                                        )
                                                      },
                                                      "on-edit": function (
                                                        $event
                                                      ) {
                                                        return _vm.editNote(
                                                          note,
                                                          $event
                                                        )
                                                      },
                                                      "on-archive": function (
                                                        $event
                                                      ) {
                                                        return _vm.archiveNote(
                                                          note
                                                        )
                                                      },
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mt-6",
                                        on: { click: _vm.showHistoryModal },
                                      },
                                      [_vm._v("History")]
                                    ),
                                    _c("v-card", { staticClass: "mt-6" }, [
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "pb-0 px-4 pt-4" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "text-h6 text-typo mb-4 font-weight-bold",
                                                  },
                                                  [_vm._v(" Customer Notes ")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "ul",
                                              {
                                                staticClass: "list-group mb-n2",
                                                staticStyle: {
                                                  "padding-left": "16px",
                                                  "padding-right": "10px",
                                                },
                                              },
                                              _vm._l(
                                                _vm.customer_notes,
                                                function (note) {
                                                  return _c("note", {
                                                    attrs: {
                                                      description: note.note,
                                                      date: note.date,
                                                      id: note.id,
                                                    },
                                                    on: {
                                                      "on-toggle": function (
                                                        $event
                                                      ) {
                                                        return _vm.toggleNote(
                                                          note
                                                        )
                                                      },
                                                      "on-delete": function (
                                                        $event
                                                      ) {
                                                        return _vm.deleteNote(
                                                          note
                                                        )
                                                      },
                                                      "on-edit": function (
                                                        $event
                                                      ) {
                                                        return _vm.editNote(
                                                          note,
                                                          $event
                                                        )
                                                      },
                                                      "on-archive": function (
                                                        $event
                                                      ) {
                                                        return _vm.archiveNote(
                                                          note
                                                        )
                                                      },
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            !_vm.insideSiteModal
                              ? _c(
                                  "v-row",
                                  { attrs: { justify: "start" } },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "6" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "\n                    font-weight-bold\n                    text-xs\n                    btn-default\n                    bg-gradient-danger\n                    ml-2\n                  ",
                                            attrs: {
                                              disabled:
                                                _vm.user.claims.user_role ==
                                                  "customer_edit" ||
                                                _vm.user.claims.user_role ==
                                                  "customer_view",
                                              ripple: false,
                                              elevation: 0,
                                              color: "error",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleShowConfirmModal(
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Remove Site ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { attrs: { value: "additional-info" } },
                  [
                    _c(
                      "v-card",
                      {
                        attrs: {
                          disabled:
                            _vm.user.claims.user_role == "customer_view",
                          flat: "",
                          id: "additional_card",
                        },
                      },
                      [
                        _c(
                          "v-card-text",
                          [
                            _vm.fields
                              ? _c(
                                  "v-form",
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "card-shadow border-radius-xl mt-4 mb-4 mr-10 ml-10",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "pb-0 px-8 pt-4" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "text-h6 text-typo mb-4 font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Additional Information "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "start" } },
                                          _vm._l(
                                            [
                                              "inverterManufacturer",
                                              "solarModuleWattage",
                                            ],
                                            function (field) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: _vm.fields[field].value,
                                                  staticClass: "ml-16 mr-8",
                                                  attrs: { cols: "5" },
                                                },
                                                [
                                                  _c("site-detail-input", {
                                                    attrs: {
                                                      field: _vm.fields[field],
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "start" } },
                                          _vm._l(
                                            ["internalId", "component2"],
                                            function (field) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: _vm.fields[field].value,
                                                  staticClass: "ml-16 mr-8",
                                                  attrs: { cols: "5" },
                                                },
                                                [
                                                  _c("site-detail-input", {
                                                    attrs: {
                                                      field: _vm.fields[field],
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "start" } },
                                          _vm._l(
                                            [
                                              "annualProductionEstimate",
                                              "secondaryMonitoringSystem",
                                            ],
                                            function (field) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: _vm.fields[field].value,
                                                  staticClass: "ml-16 mr-8",
                                                  attrs: { cols: "5" },
                                                },
                                                [
                                                  _c("site-detail-input", {
                                                    attrs: {
                                                      field: _vm.fields[field],
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "pb-0 px-8 pt-4" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "text-h6 text-typo mb-4 font-weight-bold",
                                                  },
                                                  [_vm._v(" Shade ")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "start" } },
                                          _vm._l(
                                            [
                                              "shadeSummer",
                                              "shadeFallSpring",
                                              "shadeWinter",
                                            ],
                                            function (field) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: _vm.fields[field].value,
                                                  staticClass: "ml-16 mr-11",
                                                  attrs: { cols: "3" },
                                                },
                                                [
                                                  _c("site-detail-input", {
                                                    attrs: {
                                                      field: _vm.fields[field],
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "pb-0 px-8 pt-4" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "text-h6 text-typo mb-4 font-weight-bold",
                                                  },
                                                  [_vm._v(" Site Plans ")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            attrs: {
                                              justify: "start",
                                              align: "baseline",
                                            },
                                          },
                                          [
                                            _vm._l(
                                              ["sitePlans"],
                                              function (field) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: _vm.fields[field]
                                                      .value,
                                                    staticClass: "ml-16",
                                                    attrs: { cols: "5" },
                                                  },
                                                  [
                                                    _c("site-detail-input", {
                                                      attrs: {
                                                        field:
                                                          _vm.fields[field],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "6" } },
                                              [
                                                _vm.currentSite.sitePlans
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: { href: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                            return _vm.openWindow(
                                                              _vm.currentSite
                                                                .sitePlans
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentSite
                                                              .sitePlans
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "pb-0 px-8 pt-4" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "text-h6 text-typo mb-4 font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Native Monitoring "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "start" } },
                                          _vm._l(
                                            [
                                              "monitoringSiteId",
                                              "monitoringApiKey",
                                            ],
                                            function (field) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: _vm.fields[field].value,
                                                  staticClass: "ml-16 mr-8",
                                                  attrs: { cols: "5" },
                                                },
                                                [
                                                  _c("site-detail-input", {
                                                    attrs: {
                                                      field: _vm.fields[field],
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "start" } },
                                          _vm._l(
                                            ["nativeMonitoringLink"],
                                            function (field) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: _vm.fields[field].value,
                                                  staticClass: "ml-16 mr-8",
                                                  attrs: { cols: "5" },
                                                },
                                                [
                                                  _c("site-detail-input", {
                                                    attrs: {
                                                      field: _vm.fields[field],
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "pb-0 px-8 pt-4" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "text-h6 text-typo mb-4 font-weight-bold",
                                                  },
                                                  [_vm._v(" Case Management ")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "start" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { cols: "12" },
                                              },
                                              [
                                                _c("v-checkbox", {
                                                  staticClass: "ml-5",
                                                  on: {
                                                    change:
                                                      _vm.managingCasesChanged,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c("div", [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "\n                              text-sm\n                              mb-0\n                              text-capitalize\n                              font-weight-bolder\n                            ",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Sun-o-vision is responsible for active case management on this site (coordinating onsite work, etc.) "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2482153025
                                                  ),
                                                  model: {
                                                    value: _vm.managingCases,
                                                    callback: function ($$v) {
                                                      _vm.managingCases = $$v
                                                    },
                                                    expression: "managingCases",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "pb-0 px-8 pt-4" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "text-h6 text-typo mb-4 font-weight-bold",
                                                  },
                                                  [_vm._v(" Preferences ")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "start" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c("v-checkbox", {
                                                  staticClass: "ml-5",
                                                  on: {
                                                    change:
                                                      _vm.annualVisitChanged,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c("div", [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "\n                              text-sm\n                              mb-0\n                              text-capitalize\n                              font-weight-bolder\n                            ",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Annual Visit "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3141860565
                                                  ),
                                                  model: {
                                                    value: _vm.annualVisit,
                                                    callback: function ($$v) {
                                                      _vm.annualVisit = $$v
                                                    },
                                                    expression: "annualVisit",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c("v-checkbox", {
                                                  staticClass: "ml-3",
                                                  on: {
                                                    change:
                                                      _vm.performanceGuaranteeChanged,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c("div", [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "\n                              text-sm\n                              mb-0\n                              text-capitalize\n                              font-weight-bolder\n                            ",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Performance Guarantee "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3712423167
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.performanceGuarantee,
                                                    callback: function ($$v) {
                                                      _vm.performanceGuarantee =
                                                        $$v
                                                    },
                                                    expression:
                                                      "performanceGuarantee",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { attrs: { value: "financials" } },
                  [
                    _c(
                      "v-card",
                      {
                        attrs: {
                          disabled:
                            _vm.user.claims.user_role == "customer_view",
                          flat: "",
                          id: "financials_card",
                        },
                      },
                      [
                        _c(
                          "v-card-text",
                          [
                            _vm.fields
                              ? _c(
                                  "v-form",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "card-shadow border-radius-xl mt-4 mb-8",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pb-0 px-8 pt-4",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass:
                                                              "text-h6 text-typo mb-4 font-weight-bold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Financial Information "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { justify: "start" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.user.claims
                                                                .user_role ==
                                                                "super_user" ||
                                                              _vm.user.claims
                                                                .user_role ==
                                                                "customer_admin",
                                                            expression:
                                                              "\n                          user.claims.user_role == 'super_user' ||\n                          user.claims.user_role == 'customer_admin'\n                        ",
                                                          },
                                                        ],
                                                        staticClass: "ml-12",
                                                        attrs: { cols: "5" },
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          key: _vm.fields[
                                                            "billingPlanId"
                                                          ].value,
                                                          attrs: {
                                                            items:
                                                              _vm.billingPlans,
                                                            "item-text": "name",
                                                            "item-value": "id",
                                                            label:
                                                              "Billing Plan",
                                                            "allow-overflow": true,
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.billingPlanChange,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.billingPlanId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.billingPlanId =
                                                                $$v
                                                            },
                                                            expression:
                                                              "billingPlanId",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: {
                                                      justify: "",
                                                      start: "",
                                                    },
                                                  },
                                                  _vm._l(
                                                    [
                                                      "annualFee",
                                                      "expDate",
                                                      "utilityRate",
                                                    ],
                                                    function (field) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.user.claims
                                                                  .user_role ==
                                                                  "super_user" ||
                                                                _vm.user.claims
                                                                  .user_role ==
                                                                  "customer_admin",
                                                              expression:
                                                                "\n                          user.claims.user_role == 'super_user' ||\n                          user.claims.user_role == 'customer_admin'\n                        ",
                                                            },
                                                          ],
                                                          key: _vm.fields[field]
                                                            .value,
                                                          staticClass:
                                                            "ml-12 mr-n1",
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c(
                                                            "site-detail-input",
                                                            {
                                                              attrs: {
                                                                field:
                                                                  _vm.fields[
                                                                    field
                                                                  ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", "max-width": "290" },
                    model: {
                      value: _vm.showConfirmModal,
                      callback: function ($$v) {
                        _vm.showConfirmModal = $$v
                      },
                      expression: "showConfirmModal",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title"),
                        _c("v-card-text", [
                          _vm._v("Do you really want to remove this site?"),
                        ]),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "black", outlined: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleShowConfirmModal(false)
                                  },
                                },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "error" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeSite()
                                  },
                                },
                              },
                              [_vm._v(" Remove ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", "max-width": "450" },
                    model: {
                      value: _vm.fileUploadModal,
                      callback: function ($$v) {
                        _vm.fileUploadModal = $$v
                      },
                      expression: "fileUploadModal",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title"),
                        _c("v-card-text", [
                          _vm._v("Select Plan file to upload"),
                        ]),
                        _c("div", { staticStyle: { "padding-left": "5px" } }, [
                          _c("input", {
                            attrs: { type: "file" },
                            on: { change: _vm.onFileChange },
                          }),
                        ]),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "black", outlined: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleShowFileUploadModal(false)
                                  },
                                },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "error" },
                                on: {
                                  click: function ($event) {
                                    return _vm.uploadPlan()
                                  },
                                },
                              },
                              [_vm._v(" Upload ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "650" },
                    model: {
                      value: _vm.historyModal,
                      callback: function ($$v) {
                        _vm.historyModal = $$v
                      },
                      expression: "historyModal",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      { attrs: { "min-height": "300" } },
                      [
                        _c("v-card-title", [_vm._v("History")]),
                        _c("v-divider"),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "ul",
                              {
                                staticClass: "list-group mb-n2 mt-4",
                                staticStyle: { "padding-right": "16px" },
                              },
                              _vm._l(_vm.historyNotes, function (note) {
                                return _c("note", {
                                  attrs: {
                                    description: note.note,
                                    date: note.date,
                                    id: note.id,
                                    archived: note.is_archived,
                                  },
                                  on: {
                                    "on-toggle": function ($event) {
                                      return _vm.toggleNote(note)
                                    },
                                    "on-delete": function ($event) {
                                      return _vm.deleteNote(note)
                                    },
                                    "on-edit": function ($event) {
                                      return _vm.editNote(note, $event)
                                    },
                                    "on-archive": function ($event) {
                                      return _vm.archiveNote(note)
                                    },
                                  },
                                })
                              }),
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _vm.showGetDataResponseAlert
                  ? _c(
                      "v-alert",
                      { attrs: { type: _vm.getDataResponseAlertType } },
                      [_vm._v(" " + _vm._s(_vm.getDataResponseMessage) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.objectPathBeingEdited
                ? _c("div", { staticClass: "scrim" })
                : _vm._e(),
            ]),
            _c("new-contact-modal", {
              attrs: { siteID: _vm.currentSiteId },
              on: { "my-message": _vm.newContactCreated },
              model: {
                value: _vm.showNewContactModal,
                callback: function ($$v) {
                  _vm.showNewContactModal = $$v
                },
                expression: "showNewContactModal",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }